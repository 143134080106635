import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { Dispatch, ReactElement, SetStateAction, useState } from "react";

import Heading from "../../../../../atoms/heading/Heading";
import { Result } from "../../../../../organisms/result/domain/result";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { PetSpecies } from "../../../../../settings/pet";
import { HealthProductAlias, ProductId } from "../../../../../settings/products";
import { formatCurrency } from "../../../../../utils/currency";
import useVisibilityTracker from "../../../../../utils/hooks/useVisibilityTracker";
import { usePromotionCode } from "../../../application/promotion-code-use-cases";
import {
  useSelectHealthProduct,
  useShoppingCart,
} from "../../../application/shopping-cart-use-cases";
import { Product } from "../../../domain/product";
import { ShoppingCart } from "../../../domain/shopping-cart";
import { PaymentIntervalMonths } from "../../../settings/payments";
import CatBarkibu80 from "../../images/cat-barkibu-80.svg";
import CatBarkibu100 from "../../images/cat-barkibu-100.svg";
import DogBarkibu80 from "../../images/dog-barkibu-80.svg";
import DogBarkibu100 from "../../images/dog-barkibu-100.svg";
import HealthProductCard from "../../molecules/health-product-card/HealthProductCard";
import * as styles from "./HealthProductSelector.module.scss";

interface HealthProductSelectorProps {
  isDisabled: boolean;
  benefits: any;
  features: Record<string, string | string[]>[];
  hasBecasDiscount?: boolean;
  hasHealthProductError?: boolean;
  setHasHealthProductError: Dispatch<SetStateAction<boolean>>;
}

const HealthProductSelector = ({
  isDisabled,
  benefits,
  features,
  hasBecasDiscount,
  hasHealthProductError,
  setHasHealthProductError,
}: HealthProductSelectorProps): ReactElement => {
  const { ref } = useVisibilityTracker("health-product-selector", {
    threshold: 0.5,
  });
  const { t, i18n } = useTranslation();
  const result: Result | null = useResult();
  const cart: ShoppingCart = useShoppingCart();
  const selectHealthProduct = useSelectHealthProduct();
  const promotionCode = usePromotionCode();
  const [cardsExpanded, setCardsExpanded] = useState(false);

  const getDiscountedPrice = (productId: ProductId) => {
    return promotionCode && hasBecasDiscount
      ? parse(
          t("pg_quotes.health_product_selector.monthly", {
            productPrice: formatCurrency(
              cart.getMonthlyDiscountedPriceByHealthProduct(productId),
              i18n.language,
              cart.currency
            ),
          })
        )
      : undefined;
  };

  const getHealthProductImage = (productId: ProductId) => {
    const resultSpecies = result?.pet_species || PetSpecies.dog;
    const imageComponents = {
      [ProductId.healthBasic]:
        resultSpecies === PetSpecies.dog ? <DogBarkibu80 /> : <CatBarkibu80 />,
      [ProductId.healthPlatinum]:
        resultSpecies === PetSpecies.dog ? <DogBarkibu100 /> : <CatBarkibu100 />,
    };

    return imageComponents[productId] || <DogBarkibu80 />;
  };

  const getHealthProductName = (product: Product): string => {
    if (cart.getProduct(ProductId.healthPlatinum)) {
      switch (product.id) {
        case ProductId.healthBasic:
          return HealthProductAlias.healthBasic;
        case ProductId.healthPlatinum:
          return HealthProductAlias.healthPlatinum;
        default:
          return product.name;
      }
    }

    return product.name;
  };

  const getHealthProductPrice = (productId: ProductId) => {
    return parse(
      t(
        `pg_quotes.health_product_selector.${
          cart.paymentIntervalMonths === PaymentIntervalMonths.yearly ? "yearly" : "monthly"
        }`,
        {
          productPrice: formatCurrency(
            cart.getPriceByHealthProduct(productId),
            i18n.language,
            result?.currency
          ),
        }
      )
    );
  };

  const getHealthProductFeatures = (productId: ProductId) => {
    const getFeatures = (healthAlias: HealthProductAlias): string[] => {
      return features
        .filter((item: { locale: string; entryTitle: string; features: string[] }) => {
          return item.entryTitle.toLowerCase() === healthAlias;
        })
        .flatMap(
          (item: { locale: string; entryTitle: string; features: string[] }) => item.features
        );
    };

    switch (productId) {
      case ProductId.healthPlatinum:
        return getFeatures(HealthProductAlias.healthPlatinum);
      case ProductId.healthBasic:
      default:
        return getFeatures(HealthProductAlias.healthBasic);
    }
  };

  const healthProducts = cart
    .getHealthProducts()
    .map((healthProduct: Product) => {
      return {
        value: healthProduct.id as ProductId,
        image: getHealthProductImage(healthProduct.id),
        name: getHealthProductName(healthProduct),
        features: getHealthProductFeatures(healthProduct.id),
        price: getHealthProductPrice(healthProduct.id),
        benefits: benefits.conditionsItems,
      };
    })
    .sort((a, b): number => (a.name > b.name ? -1 : 1));

  const handleSelectHealthProduct = (value: ProductId): void => {
    setHasHealthProductError(false);
    selectHealthProduct(value);
  };

  return (
    <>
      <section ref={ref} className={cn(styles.healthProductSelector)}>
        <Heading level={1} adoptionClassName={styles.title}>
          {t("pg_quotes.health_product_selector.title", { petName: result?.pet_name })}
        </Heading>
        <ul>
          {healthProducts.map((healthProduct) => {
            return (
              <li
                key={healthProduct.value}
                className={cn({
                  [styles.cardsExpanded]: cardsExpanded,
                  [styles.singleHealthProductCard]: healthProducts.length === 1,
                })}
              >
                <HealthProductCard
                  healthProduct={healthProduct}
                  handleSelectHealthProduct={() => handleSelectHealthProduct(healthProduct.value)}
                  isSelected={cart.getSelectedHealthProduct()?.id === healthProduct.value}
                  isDisabled={isDisabled}
                  isSelectable={healthProducts.length > 1}
                  isExpanded={cardsExpanded}
                  handleExpanded={() => setCardsExpanded(!cardsExpanded)}
                  discountedPrice={getDiscountedPrice(healthProduct.value)}
                  appliedPromotionCode={promotionCode?.code}
                />
              </li>
            );
          })}
        </ul>
        {hasHealthProductError && (
          <p className={styles.errorMessage}>{t("pg_quotes.health_product_selector.error")}</p>
        )}
      </section>
    </>
  );
};

export default HealthProductSelector;
