import cn from "classnames";
import { ReactElement } from "react";

import SectionTitle from "../../../../../molecules/section-title/SectionTitle";
import useVisibilityTracker from "../../../../../utils/hooks/useVisibilityTracker";
import * as styles from "./QuotesInfoCard.module.scss";

interface QuotesInfoCardProps {
  visibilityTrackerId?: string;
  children: ReactElement | ReactElement[];
  headingLevel?: number;
  headingTitle?: string;
  adoptionClassName?: string;
  icon: string;
}

const QuotesInfoCard = ({
  visibilityTrackerId = "",
  children,
  headingLevel = 2,
  headingTitle,
  adoptionClassName,
  icon,
}: QuotesInfoCardProps): ReactElement => {
  const { ref } = useVisibilityTracker(visibilityTrackerId, {
    threshold: 0.5,
  });

  return (
    <div ref={ref} className={cn(styles.quoteInfoCard, adoptionClassName)}>
      <div className={styles.iconWrapper}>
        <svg className={cn(styles.icon)} role="img" aria-hidden="true">
          <use href={`#icon-${icon}`} />
        </svg>
      </div>
      {headingTitle && (
        <SectionTitle
          headingLevel={headingLevel}
          title={headingTitle}
          adoptionClassName={styles.sectionTitle}
        />
      )}
      {children}
    </div>
  );
};

export default QuotesInfoCard;
