import { useEffect, useState } from "react";

const useScreenDetector = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isTiny = width <= 374;
  const isSmall = width >= 375 && width <= 500;
  const isMedium = width >= 501 && width <= 900;
  const isLarge = width >= 901 && width <= 1100;

  return {
    isTiny,
    isSmall,
    isMedium,
    isLarge,
  };
};

export default useScreenDetector;
