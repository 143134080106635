import cn from "classnames";
import { useInView } from "framer-motion";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { ReactElement, ReactNode, useEffect, useRef } from "react";

import Button from "../../../../../atoms/button/Button";
import Heading from "../../../../../atoms/heading/Heading";
import TrackableLink from "../../../../../atoms/trackable-link/TrackableLink";
import CheckList from "../../../../../molecules/check-list/CheckList";
import { ProductId } from "../../../../../settings/products";
import { Events, track } from "../../../../../utils/analytics";
import useScreenDetector from "../../../../../utils/hooks/useScreenDetector";
import DiscountBadge, { DiscountBadgeVariants } from "../../atoms/discount-badge/DiscountBadge";
import GlAddon from "../../organisms/gl-addon/GlAddon";
import * as styles from "./HealthProductCard.module.scss";

interface HealthProductElement {
  value: ProductId;
  image: ReactElement | undefined;
  name: string;
  features: string[];
  price: ReactNode;
  benefits: {
    title: string;
    subtitle?: string;
    items: string[];
    type: string;
  }[];
}

interface HealthProductCardProps {
  healthProduct: HealthProductElement;
  handleSelectHealthProduct: (value: ProductId) => void;
  isSelected: boolean;
  isDisabled: boolean;
  isSelectable: boolean;
  isExpanded: boolean;
  handleExpanded: () => void;
  discountedPrice?: ReactNode;
  appliedPromotionCode?: string;
}

const HealthProductCard = ({
  healthProduct,
  handleSelectHealthProduct,
  isSelected,
  isDisabled,
  isSelectable,
  isExpanded,
  handleExpanded,
  discountedPrice,
  appliedPromotionCode,
}: HealthProductCardProps): ReactElement => {
  const { value, image, name, features, price, benefits } = healthProduct;
  const { t } = useTranslation();
  const ref = useRef(null);
  const inView = useInView(ref, {
    amount: 0.8,
  });
  const isLargeScreen = useScreenDetector().isLarge;

  const handleClick = (): void => {
    track(Events.CLICKED_CTA, {
      eventSender: "health-product-card-cta",
      selection: value,
    });

    handleSelectHealthProduct(value);
  };

  const toggle = (): void => {
    const properties = { block: healthProduct.value };
    const eventName = !isExpanded ? Events.BLOCK_EXPANDED : Events.BLOCK_COLLAPSED;

    handleExpanded();
    track(eventName, properties);
  };

  useEffect(() => {
    if (inView && healthProduct.value === ProductId.healthPlatinum && !isLargeScreen) {
      track(Events.SECTION_VIEWED_BROWSER, {
        section: "health-platinum-card",
      });
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={cn(styles.healthProductCard, {
        [styles.isSelected]: isSelected && isSelectable,
        [styles.isUnselectable]: !isSelectable,
      })}
    >
      <div className={styles.imageWrapper}>{image}</div>
      <Heading level={5} adoptionClassName={styles.name}>
        {name}
      </Heading>
      <span className={cn(styles.price)}>{price}</span>
      {discountedPrice && appliedPromotionCode && (
        <section className={styles.discountedPriceWrapper}>
          <div>
            <span className={styles.discountedPrice}>{discountedPrice}</span>
            <span className={styles.discountedPriceMessage}>
              {t("quotes.cart.summary.becas_discount.message")}
            </span>
          </div>
          <DiscountBadge
            discountName={appliedPromotionCode}
            adoptionClassName={styles.discountBadge}
            variantName={DiscountBadgeVariants.alternate}
          />
        </section>
      )}
      {isSelectable && (
        <Button
          adoptionClassName={cn(styles.button)}
          onClick={handleClick}
          disabled={isSelected || isDisabled}
        >
          {isSelected ? t("common.selected") : t("common.select")}
        </Button>
      )}
      <CheckList
        id={`${healthProduct.value}_features`}
        items={features}
        adoptionClassName={styles.featuresCheckList}
      />
      {value === ProductId.healthPlatinum && (
        <p className={styles.featuresNote}>
          {t("pg_quotes.health_card.features.health_platinum_note")}
        </p>
      )}
      <div
        id={healthProduct.value}
        className={cn(styles.benefitsWrapper, { [styles.isExpanded]: isExpanded })}
      >
        {benefits.map(
          (benefit, index) =>
            benefit.items && (
              <>
                {benefit.type === "gl-addon" ? (
                  <GlAddon benefit={benefit} />
                ) : (
                  <div key={index}>
                    <Heading level={6} adoptionClassName={styles.benefitTitle}>
                      {benefit.title}
                    </Heading>
                    <CheckList
                      key={index}
                      id={`${healthProduct.value}-benefits`}
                      items={benefit.items}
                    />
                  </div>
                )}
              </>
            )
        )}
        <TrackableLink
          to={t("pg_quotes.health_card.more_info.link")}
          adoptionClassName={styles.moreInfoMessage}
          openInNewWindow={true}
          eventSender={"Health product card - More info link"}
        >
          {parse(t("pg_quotes.health_card.more_info.message"))}
        </TrackableLink>
        {isSelectable && (
          <Button
            adoptionClassName={cn(styles.button, styles.bottomButton)}
            onClick={handleClick}
            disabled={isSelected || isDisabled}
          >
            {isSelected ? t("common.selected") : t("common.select")}
          </Button>
        )}
      </div>
      <Button
        type={"button"}
        className={cn(styles.expandableButton)}
        onClick={toggle}
        aria-controls={healthProduct.value}
        aria-expanded={isExpanded}
      >
        {isExpanded ? t("common.see_less") : t("common.see_more")}
      </Button>
    </div>
  );
};

export const query = graphql`
  fragment ProductFeaturesFragment on ContentfulProductFeaturesList {
    entryTitle
    features
    locale: node_locale
  }
`;

export default HealthProductCard;
